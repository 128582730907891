<template>
  <CCol cols="12" xl="12" lg="12" md="12" sm="12">
    <CCard class="mb-0">
      <CCardHeader class="pb-1">
        <CRow>
          <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="text-left pt-0 pb-0">
            {{$t('connect.Posts_with_best_engagement')}}
          </CCol>       
        </CRow>       
      </CCardHeader>
      <CCardBody class="p-0 has_tabs">
        <CRow>
          <CCol cols="12" lg="12" class="pb-0 pt-0">
            <b-tabs class="no_borders mb-0" type="is-boxed" :animated="false" @input="getPostEngagement" v-bind:class="{'remove_bottom_content_padding' : !isPaginated}">
              <b-tab-item>              
                <template slot="header">
                  <span>{{ $t('common.last_week')}}</span>
                </template>
                <template>
                  <CRow>
                    <CCol cols="12" lg="12" v-bind:class="{'p-0' : !loadingData}">
                      <loadingSpinner mode="auto" v-if="loadingData" :content="$t('common.Loading')"/>
                      <b-table v-else
                               class="data_table"
                               :data="posts"
                               :striped="true"
                               :hoverable="true"
                               :mobile-cards="true"
                               :paginated="isPaginated"
                               :per-page="perPage"
                               :current-page.sync="currentPage"
                               :pagination-simple="isPaginationSimple"
                               :pagination-position="paginationPosition"
                               :default-sort-direction="defaultSortDirection"
                               :sort-icon="sortIcon"
                               :sort-icon-size="sortIconSize"
                               default-sort="engagement_score">
                                                  
                        <template slot-scope="props">              
                          <b-table-column field="posted_by" label="" width="5%">                                                        
                            <userProfilePopover v-if="props.row.user_is_system_account !== 'Y'" ref="userProfilePopover" mode="user" :userName="props.row.posted_by_name" :userProfileImage="props.row.profile_image" :userIdExternal="props.row.user_id_external" :senderIdExternal="null" popoverPlacement="right"/>
                            <div v-else-if="props.row.user_is_system_account == 'Y' && cdnBaseUrl" class="profile_image " v-bind:style="{ backgroundImage: 'url(' + getLogoSrc() + ')' }"></div>
                          </b-table-column>                          
                          <b-table-column field="title" :label="$t('sw.message')" width="45%">
                            <span class="pointer" @click="openSidebarRight('sw_post_preview', { post_id_external: props.row.post_id_external });">{{props.row.title}}</span>
                          </b-table-column>
                          <b-table-column field="sw_group_name" :label="$t('sw.posted_in')">
                            {{props.row.sw_group_name !== null ? props.row.sw_group_name : "-"}}
                          </b-table-column>                           
                          <b-table-column field="engagement_score" sortable>
                            <span class="d-flex align-items-center">
                              <i class="icon top fas fa-tachometer-alt mr-1"/>{{ props.row.engagement_score }}
                            </span>                        
                          </b-table-column>                  
                          <b-table-column field="insights.reach.percentage" custom-key="percentage" sortable>
                            <span class="d-flex align-items-center">
                              <i class="icon top fas fa-percentage mr-1"/>{{ props.row.insights.reach.percentage }}
                            </span>
                          </b-table-column>
                          <b-table-column field="insights.reach.count.views" custom-key="views" sortable>
                            <span class="d-flex align-items-center">
                              <i class="icon top far fa-user mr-1"/>{{ props.row.insights.reach.count.views }} <span v-if="props.row.insights.reach.count.unique_views > 0" class="ml-1">({{props.row.insights.reach.count.unique_views}})</span>
                            </span>                        
                          </b-table-column>              
                          <b-table-column field="total_replies" custom-key="replies" sortable>
                            <span class="d-flex align-items-center pointer" @click="openSidebarRight('sw_post_preview', { post_id_external: props.row.post_id_external })">
                              <i class="icon top far fa-comments mr-1"/>{{ props.row.total_replies }}
                            </span>
                          </b-table-column>
                          <b-table-column field="actions">
                            <div class="d-flex justify-content-lg-end">                              
                              <CButton class="m-0" color="primary" @click="showPostInsights( props.row.post_id_external )">
                                <i class="far fa-chart-bar"/>
                              </CButton>
                            </div>
                          </b-table-column>                                                                
                        </template>
                        <template slot="empty">
                          <div class="text-center">
                            <span>{{ $t('connect.No_post_engagement_during_last_week') }}</span>
                          </div>                
                        </template>                
                      </b-table>
                    </CCol>
                  </CRow>
                </template>
              </b-tab-item>
              <b-tab-item>              
                <template slot="header">
                  <span>{{ $t('common.last_month')}}</span>
                </template>
                <template>
                  <CRow>
                    <CCol cols="12" lg="12" v-bind:class="{'p-0' : !loadingData}">
                      <loadingSpinner mode="auto" v-if="loadingData" :content="$t('common.Loading')"/>
                      <b-table v-else
                               class="data_table"
                               :data="posts"
                               :striped="true"
                               :hoverable="true"
                               :mobile-cards="true"
                               :paginated="isPaginated"
                               :per-page="perPage"
                               :current-page.sync="currentPage"
                               :pagination-simple="isPaginationSimple"
                               :pagination-position="paginationPosition"
                               :default-sort-direction="defaultSortDirection"
                               :sort-icon="sortIcon"
                               :sort-icon-size="sortIconSize"
                               default-sort="engagement_score">
                                                  
                        <template slot-scope="props">
                          <b-table-column field="posted_by" label="" width="5%">                                                        
                            <userProfilePopover v-if="props.row.user_is_system_account !== 'Y'" ref="userProfilePopover" mode="user" :userName="props.row.posted_by_name" :userProfileImage="props.row.profile_image" :userIdExternal="props.row.user_id_external" :senderIdExternal="null" popoverPlacement="right"/>
                            <div v-else-if="props.row.user_is_system_account == 'Y' && cdnBaseUrl" class="profile_image " v-bind:style="{ backgroundImage: 'url(' + getLogoSrc() + ')' }"></div>
                          </b-table-column>                          
                          <b-table-column field="title" :label="$t('sw.message')" width="45%">
                            <span class="pointer" @click="openSidebarRight('sw_post_preview', { post_id_external: props.row.post_id_external });">{{props.row.title}}</span>
                          </b-table-column>
                          <b-table-column field="sw_group_name" :label="$t('sw.posted_in')">
                            {{props.row.sw_group_name !== null ? props.row.sw_group_name : "-"}}
                          </b-table-column>                           
                          <b-table-column field="engagement_score" sortable>
                            <span class="d-flex align-items-center">
                              <i class="icon top fas fa-tachometer-alt mr-1"/>{{ props.row.engagement_score }}
                            </span>                        
                          </b-table-column>                  
                          <b-table-column field="insights.reach.percentage" custom-key="percentage" sortable>
                            <span class="d-flex align-items-center">
                              <i class="icon top fas fa-percentage mr-1"/>{{ props.row.insights.reach.percentage }}
                            </span>
                          </b-table-column>
                          <b-table-column field="insights.reach.count.views" custom-key="views" sortable>
                            <span class="d-flex align-items-center">
                              <i class="icon top far fa-user mr-1"/>{{ props.row.insights.reach.count.views }} <span v-if="props.row.insights.reach.count.unique_views > 0" class="ml-1">({{props.row.insights.reach.count.unique_views}})</span>
                            </span>                        
                          </b-table-column>                  
                          <b-table-column field="total_replies" custom-key="replies" sortable>
                            <span class="d-flex align-items-center pointer" @click="openSidebarRight('sw_post_preview', { post_id_external: props.row.post_id_external })">
                              <i class="icon top far fa-comments mr-1"/>{{ props.row.total_replies }}
                            </span>
                          </b-table-column>
                          <b-table-column field="actions">
                            <div class="d-flex justify-content-lg-end">                              
                              <CButton class="m-0" color="primary" @click="showPostInsights( props.row.post_id_external )">
                                <i class="far fa-chart-bar"/>
                              </CButton>
                            </div>
                          </b-table-column>                          
                        </template>
                        <template slot="empty">
                          <div class="text-center">
                            <span>{{ $t('connect.No_post_engagement_during_last_month') }}</span>
                          </div>                
                        </template>                
                      </b-table>
                    </CCol>
                  </CRow>
                </template>
              </b-tab-item>              
              <b-tab-item>
                <template slot="header">                
                  <span>{{ $t('common.last_3_months')}}</span>
                </template>
                <template>
                  <CRow>
                    <CCol cols="12" lg="12" v-bind:class="{'p-0' : !loadingData}">
                      <loadingSpinner mode="auto" v-if="loadingData" :content="$t('common.Loading')"/>
                      <b-table v-else
                               class="data_table"
                               :data="posts"
                               :striped="true"
                               :hoverable="true"
                               :mobile-cards="true"
                               :paginated="isPaginated"
                               :per-page="perPage"
                               :current-page.sync="currentPage"
                               :pagination-simple="isPaginationSimple"
                               :pagination-position="paginationPosition"
                               :default-sort-direction="defaultSortDirection"
                               :sort-icon="sortIcon"
                               :sort-icon-size="sortIconSize"
                               default-sort="engagement_score">
                                                  
                        <template slot-scope="props">
                          <b-table-column field="posted_by" label="" width="5%">                                                        
                            <userProfilePopover v-if="props.row.user_is_system_account !== 'Y'" ref="userProfilePopover" mode="user" :userName="props.row.posted_by_name" :userProfileImage="props.row.profile_image" :userIdExternal="props.row.user_id_external" :senderIdExternal="null" popoverPlacement="right"/>
                            <div v-else-if="props.row.user_is_system_account == 'Y' && cdnBaseUrl" class="profile_image " v-bind:style="{ backgroundImage: 'url(' + getLogoSrc() + ')' }"></div>
                          </b-table-column>                          
                          <b-table-column field="title" :label="$t('sw.message')" width="45%">
                            <span class="pointer" @click="openSidebarRight('sw_post_preview', { post_id_external: props.row.post_id_external });">{{props.row.title}}</span>
                          </b-table-column>
                          <b-table-column field="sw_group_name" :label="$t('sw.posted_in')">
                            {{props.row.sw_group_name !== null ? props.row.sw_group_name : "-"}}
                          </b-table-column>                          
                          <b-table-column field="engagement_score" sortable>
                            <span class="d-flex align-items-center">
                              <i class="icon top fas fa-tachometer-alt mr-1"/>{{ props.row.engagement_score }}
                            </span>                        
                          </b-table-column>                  
                          <b-table-column field="insights.reach.percentage" custom-key="percentage" sortable>
                            <span class="d-flex align-items-center">
                              <i class="icon top fas fa-percentage mr-1"/>{{ props.row.insights.reach.percentage }}
                            </span>
                          </b-table-column>
                          <b-table-column field="insights.reach.count.views" custom-key="views" sortable>
                            <span class="d-flex align-items-center">
                              <i class="icon top far fa-user mr-1"/>{{ props.row.insights.reach.count.views }} <span v-if="props.row.insights.reach.count.unique_views > 0" class="ml-1">({{props.row.insights.reach.count.unique_views}})</span>
                            </span>                        
                          </b-table-column>                  
                          <b-table-column field="total_replies" custom-key="replies" sortable>
                            <span class="d-flex align-items-center pointer" @click="openSidebarRight('sw_post_preview', { post_id_external: props.row.post_id_external })">
                              <i class="icon top far fa-comments mr-1"/>{{ props.row.total_replies }}
                            </span>
                          </b-table-column>
                          <b-table-column field="actions">
                            <div class="d-flex justify-content-lg-end">                              
                              <CButton class="m-0" color="primary" @click="showPostInsights( props.row.post_id_external )">
                                <i class="far fa-chart-bar"/>
                              </CButton>
                            </div>
                          </b-table-column>
                        </template>
                        <template slot="empty">
                          <div class="text-center">
                            <span>{{ $t('connect.No_post_engagement_during_last_3_months') }}</span>
                          </div>                
                        </template>                
                      </b-table>
                    </CCol>
                  </CRow>
                </template>
              </b-tab-item>
            </b-tabs>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  </CCol>
</template>

<script>
import axios from 'axios'
import loadingSpinner from '@/components/common/loadingSpinner.vue'
import userProfilePopover from '@/components/common/userProfilePopover.vue';

export default {
  name: 'EmployeeActivity',
  components: {
    loadingSpinner,
    userProfilePopover
  },
  data: () => {
    return {
      cdnBaseUrl: null,
      apiBaseUrl: null,
      clientToken: null,
      companyIdExternal: null,
      companyImageId: null,
      environmentTag: null,     
      loadingData: true,
      posts: [],
      isPaginated: false,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      currentPage: 1,
      perPage: 5,
      defaultSortDirection: 'desc',
      sortIcon: 'arrow-up',
      sortIconSize: 'is-small',
      showInactive: false,
      searchEnabled: true,
      dateFrom: new Date(),
      dateTo: new Date()      
    }
  },
  methods: {
    getLogoSrc() {
      if(!this.companyImageId) {
        return `${this.apiBaseUrl}/v1/common/cdn/file/image/base-dashboard/logo/${this.environmentTag}_logo.png/${this.clientToken}`;
      } else {
        return `${this.apiBaseUrl}/v1/common/cdn/file/image/base-dashboard/${this.companyIdExternal}/${this.companyImageId}.png/${this.clientToken}`;
      }
    },    
    getPostEngagement(e) {
      let selectedTabIndex = e;      
      // Start the loader
      this.loadingData = true;
      // Reset the dateFrom value
      this.dateFrom = new Date();
      if(selectedTabIndex == 0) {
        this.dateFrom.setDate(this.dateFrom.getDate() - 7);
      } else if(selectedTabIndex == 1) {
        this.dateFrom.setDate(this.dateFrom.getDate() - 30);
      } else if(selectedTabIndex == 2) {
        this.dateFrom.setDate(this.dateFrom.getDate() - 90);
      }    
      // Format the dates for the API   
      let dateFromAPI = this.$luxon(this.dateFrom.toISOString(), "yyyy-MM-dd");
      let dateToAPI = this.$luxon(this.dateTo.toISOString(), "yyyy-MM-dd");
      // Get the posts
      axios.get(process.env.VUE_APP_API_URL + '/v1/insights/connect/engagement/' + dateFromAPI + '/' + dateToAPI)      
      .then(res => {
        this.posts = res.data.data;
        // Sort the posts on the engagement_score value
        this.posts.sort(function(x, y) {
          if(parseFloat(x['engagement_score']) == parseFloat(y['engagement_score'])) return 0;
          else if(parseFloat(x['engagement_score']) < parseFloat(y['engagement_score'])) return 1;
          else return -1;
        });
        // Slice the array to keep the posts with the top 20 engagement scores
        this.posts = this.posts.slice(0, 20);        
        // Check if the pagination should be activated
        (this.posts.length > this.perPage) ? this.isPaginated = true : this.isPaginated = false;
          // Close the loader
        this.loadingData = false;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    showPostInsights (id) {
      this.$router.push({path: `/insights/connect/post/${id.toString()}`});     
    }    
  },
  mounted: function(){
    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.cdnBaseUrl = process.env.VUE_APP_CDN_URL;
    this.clientToken = localStorage.getItem('token');

    if(localStorage.getItem('companyIdExternal') !== null) this.companyIdExternal = localStorage.getItem('companyIdExternal');
    if(localStorage.getItem('companyImageId') !== null) this.companyImageId = localStorage.getItem('companyImageId');
    if(localStorage.getItem('environmentTag') !== null) this.environmentTag = localStorage.getItem('environmentTag');

    // Set the default for dateFrom to today minus 7 days
    this.dateFrom.setDate(this.dateFrom.getDate() - 7);
    // Format the dates for the API   
    let dateFromAPI = this.$luxon(this.dateFrom.toISOString(), "yyyy-MM-dd");
    let dateToAPI = this.$luxon(this.dateTo.toISOString(), "yyyy-MM-dd")
    // Get the posts    
    axios.get(process.env.VUE_APP_API_URL + '/v1/insights/connect/engagement/' + dateFromAPI + '/' + dateToAPI)      
    .then(res => {
      this.posts = res.data.data;
      // Sort the posts on the engagement_score value
      this.posts.sort(function(x, y) {
        if(parseFloat(x['engagement_score']) == parseFloat(y['engagement_score'])) return 0;
        else if(parseFloat(x['engagement_score']) < parseFloat(y['engagement_score'])) return 1;
        else return -1;
      });
      // Slice the array to keep the posts with the top 20 engagement scores
      this.posts = this.posts.slice(0, 20);
      // Check if the pagination should be activated
      (this.posts.length > this.perPage) ? this.isPaginated = true : this.isPaginated = false;      
      // Close the loader
      this.loadingData = false;
    })
    .catch(err => {
      console.error(err); 
    });
  } 
}
</script>
