<template>
  <CCol v-if="environmentHelpers.environment_name && environmentHelpers.environment_contact_email && environmentHelpers.environment_contact_phone && environmentHelpers.environment_schedule_call_url" cols="12" xl="12">
    <CCard class="mb-0 schedule_contact_call notice_card">
      <CCardBody>       
        <div class="d-flex align-items-center">
          <div class="flex-grow-1">
            <span class="d-block mb-1"><b>{{$t('checklist.Welcome_to', { environment_name: environmentHelpers.environment_name })}}</b></span>
            <span v-html="$t('checklist.Welcome_to_message', { environment_name: environmentHelpers.environment_name, environment_contact_email: environmentHelpers.environment_contact_email, environment_contact_phone: environmentHelpers.environment_contact_phone })"></span>            
          </div>
          <div class="pl-3">
            <CButton class="icon_button m-0 d-flex align-items-center" color="primary" @click="openContactCallSchedulePage()">
              <i class="fa-solid fa-calendar-days button_icon"/>              
              <span v-line-clamp="1" class="button_text">{{$t('checklist.Schedule_contact_call')}}</span>
            </CButton>
          </div>
        </div>
      </CCardBody>
    </CCard>
  </CCol>
</template>

<script>

export default {
  name: 'ScheduleContactCall',  
  methods: {
    openContactCallSchedulePage() {
      window.open(this.environmentHelpers.environment_schedule_call_url, '_blank');
    }
  },
  data() {
    return {
      environmentTag: null,
      environmentHelpers: {
        environment_name: null,
        environment_contact_email: null,
        environment_contact_phone: null,
        environment_schedule_call_url: null
      }
    }
  },
  mounted: function() {
    if(localStorage.getItem('environmentTag') !== null) this.environmentTag = localStorage.getItem('environmentTag');
    if(localStorage.getItem('environmentHelpers') !== null) this.environmentHelpers = JSON.parse(localStorage.getItem('environmentHelpers'));
  }
}
</script>