<template>
  <CCol cols="6" xl="6" lg="6" md="6" sm="12" class="loyalty wallet">
    <CCard class="mb-0 h-100">
      <CCardHeader class="pb-0">
        <CRow>
          <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="text-left pt-0 pb-0">
            {{ $t('loyalty.Your_wallet') }}
          </CCol>       
        </CRow>         
      </CCardHeader> 
      <CCardBody>       
        <CRow v-if="loadingData" class="h-100">
          <CCol cols="12" xl="12" sm="12" class="pt-0 pb-0">
            <div class="d-flex content-center h-100">
              <loadingSpinner mode="auto" :content="null"/>
            </div>
          </CCol>
        </CRow>        
        <CRow v-else class="h-100 m-0">
          <CCol cols="12" class="h-100 p-0">
            <div class="h-100 d-flex align-items-center justify-content-center">
              <div class="flex-grow-1 d-flex align-items-center justify-content-center">
                
                <div class="h-100 d-flex align-items-center justify-content-center currency">
                  <img v-show="companyWalletCurrency === 'coins'" :src="apiBaseUrl + '/v1/common/cdn/file/image/base-dashboard/credits/' + environmentTag + '_credit.png/' + clientToken"/>
                  <i v-show="companyWalletCurrency === 'euro'" class="fa-solid fa-euro-sign"></i>
                </div>

                <div v-if="companyPaymentMethodSet === false || (companyPaymentMethodSet === true && companyPaymentTypeTag === 'prepaid')" class="h-100 pl-2 d-flex align-items-center justify-content-center flex-column">                                    
                  <span class="count d-block">{{this.companyWalletCurrency === 'coins' ? companyWalletCoins : formatToCurrency(companyWalletCoins)}}</span>                  
                  <span>{{companyWalletCurrency === 'coins' ? $t('loyalty.Coins_in_wallet') : $t('loyalty.Euro_in_wallet')}}</span>
                </div>

                <div v-if="companyPaymentMethodSet === true && companyPaymentTypeTag === 'recurring'" class="h-100 pl-2 d-flex align-items-center justify-content-center flex-column">
                  <span class="count d-block infinite"><i class="fa-solid fa-infinity"></i></span>
                  <span>{{$t('loyalty.Coins_in_wallet')}}</span>
                </div>                
              </div>
              <div v-if="companyPaymentMethodSet === false || (companyPaymentMethodSet === true && companyPaymentTypeTag === 'prepaid')" class="h-100 pointer currency_switch" @click="switchCompanyWalletCurrency()">
                <div class="h-100 d-flex align-items-center justify-content-center flex-column">
                  <div class="d-flex justify-content-center">
                    <i class="fa-solid fa-repeat"></i>
                  </div>
                  <div class="switch_label">
                    <span><b>{{$t('To')}} {{this.companyWalletCurrency === 'coins' ? 'euro' : 'coins'}}</b></span>
                  </div>
                </div>
              </div>
            </div>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  </CCol>
</template>

<script>
import axios from 'axios'
import loadingSpinner from '@/components/common/loadingSpinner.vue'

export default {
  name: 'CompanyWallet',
  components: {
    loadingSpinner
  },  
  data() {
    return {
      apiBaseUrl: null,
      clientToken: null,
      loadingData: false,
      companyPaymentMethodSet: false,
      companyPaymentTypeTag: null,
      companyWalletCoins: 0,
      companyWalletAmount: 0,
      companyWalletAmountFormatted: 0,
      companyWalletCurrency: 'coins',
      environmentTag: null
    }
  },
  methods: {
    formatToCurrency(points) {
      return new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2 }).format(parseInt(points)/10);
    },
    getCompanyWallet() {
      // Start the loader
      this.loadingData = true;
      // Get the points      
      axios.get(process.env.VUE_APP_API_URL + '/v1/loyalty/wallet')
      .then(res => {
        this.companyPaymentMethodSet = res.data.data.payment_method_set;
        this.companyPaymentTypeTag = res.data.data.payment_type_tag;
        this.companyWalletCoins = res.data.data.loyalty_wallet_coins;
        this.companyWalletAmount = res.data.data.loyalty_wallet_amount;
        this.companyWalletAmountFormatted = res.data.data.loyalty_wallet_amount_formatted;
        // Close the loader
        this.loadingData = false;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    switchCompanyWalletCurrency() {
      (this.companyWalletCurrency === 'coins') ? this.companyWalletCurrency = 'euro' : this.companyWalletCurrency = 'coins';
    }    
  },
  mounted: function(){
    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.clientToken = localStorage.getItem('token');
    if(localStorage.getItem('environmentTag') !== null) this.environmentTag = localStorage.getItem('environmentTag');

    this.getCompanyWallet();
  }
}
</script>