<template>
  <CCol cols="6" xl="6" lg="6" md="6" sm="12" class="latest posts">
    <CCard class="leaderboard mb-0 h-100">
      <CCardHeader class="pb-0">
        {{ $t('points.leaderboard_users') }}
      </CCardHeader>
      <CCardBody class="has_tabs pb-0">       
        <CRow>     
          <CCol cols="12" class="p-0">
            <b-tabs class="points_leaderboard no_borders mb-0"
                    type="is-boxed"
                    :animated="false"
                    v-model="activeTab"
                    @input="currentPage = 1;"
                    v-bind:class="{'remove_bottom_content_padding' : (activeTab === 0 && !pointsLastMonthPaginated) || (activeTab === 1 && !pointsLastThreeMonthsPaginated) || (activeTab === 2 && !pointsLastYearPaginated)}">
            
              <b-tab-item>
                <template slot="header">
                  <span>{{ $t('common.Points') }} - {{ $t('common.last_30_days')}}</span>
                </template>
                <template>
                  <CRow>
                    <CCol cols="12" lg="12" class="p-0">
                      <loadingSpinner mode="auto" v-if="loadingData" :content="$t('common.Loading')"/>
                      <b-table v-else
                               class="data_table"
                               :data="pointsLastMonth"
                               :striped="true"
                               :hoverable="true"
                               :mobile-cards="true"
                               :paginated="pointsLastMonthPaginated"
                               :per-page="perPage"
                               :current-page.sync="currentPage"
                               :pagination-simple="isPaginationSimple"
                               :pagination-position="paginationPosition">
                        
                        <template slot-scope="props">
                          <b-table-column field="position" width="5%">
                            <b>#{{props.row.position}}</b>
                          </b-table-column>                          
                          <b-table-column field="name" :label="$t('common.Name')" width="60%">
                            <div class="d-flex align-items-center">
                              <userProfilePopover ref="userProfilePopover"
                                                  mode="user" 
                                                  :userName="props.row.name"
                                                  :userProfileImage="props.row.profile_image" 
                                                  :userIdExternal="props.row.user_id_external"
                                                  :senderIdExternal="null"
                                                  popoverPlacement="right"
                                                  class="mr-2">
                              </userProfilePopover>
                              <div>
                                <span>{{props.row.name}}</span>
                                <span class="d-block mt-1 meta">{{props.row.team_name}} - {{props.row.department_name}}</span>
                              </div>                        
                            </div>
                          </b-table-column>                          
                          <b-table-column field="total_points" :label="$t('common.Points')" width="25%">
                            <div class="d-flex align-items-center credits">
                              <img :src="apiBaseUrl + '/v1/common/cdn/file/image/base-dashboard/credits/' + environmentTag + '_credit.png/' + clientToken"/>
                              <span>{{props.row.total_points}} {{props.row.total_points !== 1 ? $t('common.points') : $t('common.point')}}</span>
                            </div>                            
                          </b-table-column>                            
                          <b-table-column field="actions" class="text-right" width="10%">
                            <CButton class="d-inline-block m-0" color="primary" @click="showInsights('loyalty/points/employees')">
                              <i class="fas fa-chevron-right"/>
                            </CButton>
                          </b-table-column>
                        </template>
                        <template slot="empty">
                          <div class="text-center">
                            <span>{{ $t('points.No_points_found') }}</span>
                          </div>
                        </template>                
                      </b-table>
                    </CCol>
                  </CRow>
                </template>
              </b-tab-item>
              <b-tab-item>
                <template slot="header">
                  <span>{{ $t('common.Points') }} - {{ $t('common.last_90_days')}}</span>
                </template>
                <template>
                  <CRow>
                    <CCol cols="12" lg="12" class="p-0">
                      <loadingSpinner mode="auto" v-if="loadingData" :content="$t('common.Loading')"/>
                      <b-table v-else 
                               class="data_table"
                               :data="pointsLastThreeMonths"
                               :striped="true"
                               :hoverable="true"
                               :mobile-cards="true"
                               :paginated="pointsLastThreeMonthsPaginated"
                               :per-page="perPage"
                               :current-page.sync="currentPage"
                               :pagination-simple="isPaginationSimple"
                               :pagination-position="paginationPosition">
                        
                        <template slot-scope="props">
                          <b-table-column field="position" width="5%">
                            <b>#{{props.row.position}}</b>
                          </b-table-column>                          
                          <b-table-column field="name" :label="$t('common.Name')" width="60%">
                            <div class="d-flex align-items-center">
                              <userProfilePopover ref="userProfilePopover"
                                                  mode="user" 
                                                  :userName="props.row.name"
                                                  :userProfileImage="props.row.profile_image" 
                                                  :userIdExternal="props.row.user_id_external"
                                                  :senderIdExternal="null"
                                                  popoverPlacement="right"
                                                  class="mr-2">
                              </userProfilePopover>
                              <div>
                                <span>{{props.row.name}}</span>
                                <span class="d-block mt-1 meta">{{props.row.team_name}} - {{props.row.department_name}}</span>
                              </div>                        
                            </div>
                          </b-table-column>                          
                          <b-table-column field="total_points" :label="$t('common.Points')" width="25%">
                            <div class="d-flex align-items-center credits">
                              <img :src="apiBaseUrl + '/v1/common/cdn/file/image/base-dashboard/credits/' + environmentTag + '_credit.png/' + clientToken"/>
                              <span>{{props.row.total_points}} {{props.row.total_points !== 1 ? $t('common.points') : $t('common.point')}}</span>
                            </div>                            
                          </b-table-column>                        
                          <b-table-column field="actions" class="text-right" width="10%">
                            <CButton class="d-inline-block m-0" color="primary" @click="showInsights('loyalty/points/employees')">
                              <i class="fas fa-chevron-right"/>
                            </CButton>
                          </b-table-column>
                        </template>
                        <template slot="empty">
                          <div class="text-center">
                            <span>{{ $t('points.No_points_found') }}</span>
                          </div>
                        </template>                
                      </b-table>
                    </CCol>
                  </CRow>
                </template>
              </b-tab-item>
              <b-tab-item>
                <template slot="header">
                  <span>{{ $t('common.Points') }} - {{ $t('common.last_365_days')}}</span>
                </template>
                <template>
                  <CRow>
                    <CCol cols="12" lg="12" class="p-0">
                      <loadingSpinner mode="auto" v-if="loadingData" :content="$t('common.Loading')"/>
                      <b-table v-else
                               class="data_table"
                               :data="pointsLastYear"
                               :striped="true"
                               :hoverable="true"
                               :mobile-cards="true"
                               :paginated="pointsLastYearPaginated"
                               :per-page="perPage"
                               :current-page.sync="currentPage"
                               :pagination-simple="isPaginationSimple"
                               :pagination-position="paginationPosition">
                        
                        <template slot-scope="props">
                          <b-table-column field="position" width="5%">
                            <b>#{{props.row.position}}</b>
                          </b-table-column>
                          <b-table-column field="name" :label="$t('common.Name')" width="60%">
                            <div class="d-flex align-items-center">
                              <userProfilePopover ref="userProfilePopover"
                                                  mode="user" 
                                                  :userName="props.row.name"
                                                  :userProfileImage="props.row.profile_image" 
                                                  :userIdExternal="props.row.user_id_external"
                                                  :senderIdExternal="null"
                                                  popoverPlacement="right"
                                                  class="mr-2">
                              </userProfilePopover>
                              <div>
                                <span>{{props.row.name}}</span>
                                <span class="d-block mt-1 meta">{{props.row.team_name}} - {{props.row.department_name}}</span>
                              </div>                        
                            </div>
                          </b-table-column>                          
                          <b-table-column field="total_points" :label="$t('common.Points')" width="25%">
                            <div class="d-flex align-items-center credits">
                              <img :src="apiBaseUrl + '/v1/common/cdn/file/image/base-dashboard/credits/' + environmentTag + '_credit.png/' + clientToken"/>
                              <span>{{props.row.total_points}} {{props.row.total_points !== 1 ? $t('common.points') : $t('common.point')}}</span>
                            </div>                            
                          </b-table-column>                            
                          <b-table-column field="actions" class="text-right" width="10%">
                            <CButton class="d-inline-block m-0" color="primary" @click="showInsights('loyalty/points/employees')">
                              <i class="fas fa-chevron-right"/>
                            </CButton>
                          </b-table-column>
                        </template>
                        <template slot="empty">
                          <div class="text-center">
                            <span>{{ $t('points.No_points_found') }}</span>
                          </div>
                        </template>                
                      </b-table>
                    </CCol>
                  </CRow>
                </template>
              </b-tab-item>                                                               
            </b-tabs>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  </CCol>
</template>

<script>
import axios from 'axios'
import userProfilePopover from '@/components/common/userProfilePopover.vue';
import loadingSpinner from '@/components/common/loadingSpinner.vue'

export default {
  name: 'UserPointsLeaderboard',
  components: {
    userProfilePopover,
    loadingSpinner
  },  
  data() {
    return {
      apiBaseUrl: null,
      clientToken: null,
      environmentTag: null,
      loadingData: false,
      activeTab: 0,
      pointsLastMonth: [],
      pointsLastMonthPaginated: false,
      pointsLastThreeMonths: [],
      pointsLastThreeMonthsPaginated: false,
      pointsLastYear: [],
      pointsLastYearPaginated: false,      
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      currentPage: 1,
      perPage: 5,      
    }
  },
  methods: {
    getUserPoints() {
      // Start the loader
      this.loadingData = true;
      // Get the points      
      axios.get(process.env.VUE_APP_API_URL + '/v1/insights/loyalty/leaderboard/users')
      .then(res => {
        this.pointsLastMonth = res.data.data.user_points_last_month;
        this.pointsLastThreeMonths = res.data.data.user_points_last_three_months;
        this.pointsLastYear = res.data.data.user_points_last_year;
        // Check if the pagination should be activated
        (this.pointsLastMonth.length > this.perPage) ? this.pointsLastMonthPaginated = true : this.pointsLastMonthPaginated = false;
        (this.pointsLastThreeMonths.length > this.perPage) ? this.pointsLastThreeMonthsPaginated = true : this.pointsLastThreeMonthsPaginated = false;
        (this.pointsLastYear.length > this.perPage) ? this.pointsLastYearPaginated = true : this.pointsLastYearPaginated = false;
        // Close the loader
        this.loadingData = false;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    showInsights(path) {
      this.$router.push({path: 'insights/' + path});
    }
  },
  mounted: function(){
    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.clientToken = localStorage.getItem('token');
    if(localStorage.getItem('environmentTag') !== null) this.environmentTag = localStorage.getItem('environmentTag');

    this.getUserPoints();
  }
}
</script>